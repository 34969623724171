<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <service-predict-form
      :formType="formType"
      :service="service"
      @setService="updateService"
      @deleteService="deleteService"
      @cancel="goToServicesPage"
    />
    <div v-if="hasAnalyses">
      <b-col class="col-12 col-md-8 p-3 mx-auto">
        <b-card no-body class="p-3 mb-2">
          <div class="d-flex align-items-center">
            <img src="./../assets/icons/user.svg" class="icon mr-1" />
            <h6 class="font-weight-bold mb-0">{{ $t("analyses") }}</h6>
          </div>
          <hr />
          <div>
            <service-predict-list> </service-predict-list>
          </div>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import ServicePredictForm from "../components/ServicesPredict/ServicePredictForm.vue";
import ServicePredictList from "../components/ServicesPredict/ServicePredictList.vue";
export default {
  components: {
    ServicePredictForm,
    ServicePredictList,
  },
  data() {
    return {
      formType: "EDIT",
      pageTitle: "page.services.editService",
    };
  },
  computed: {
    service() {
      return {...this.$store.getters["servicePredict/getActiveService"]};
    },
    serviceId() {
      return this.service._id;
    },
    hasAnalyses() {
      return this.service.numAnalysis > 0;
    },
  },
  methods: {
    updateService(service) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("servicePredict/updateService", service)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    deleteService(serviceId) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("servicePredict/deleteService", serviceId)
        .then(()=>{this.goToServicesPage()})
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
        
    },
    goToServicesPage() {
      this.$router.push({ name: "Services" });
    },
  },
};
</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
</style>
